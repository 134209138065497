@use "../../../../../styles/etc/env" as *;

$assetsBaseURL: "#{$s3BaseURL}assets/custom_pages/191-collabs/";
$staticAssetsBaseURL: "#{$assetsBaseURL}static/";
$seasonBaseURL: "#{$assetsBaseURL}2024-25/";

$heading-font: "Praktika Black Extended", Arial, Helvetica, sans-serif;
$body-font: "Roboto Mono ", monospace;

$teal: #00b2ae;
$off-black: #0f0f0f;
$med-gray: #646464;
$med-blue: #2088c3;

.pillButton {
	display: inline-block;
	background-color: $off-black;
	border: none;
	border-radius: 50px;
	color: #fff;
	text-decoration: none;
	transition: text-decoration 0.2s ease-in-out, background-color 0.2s ease-in-out,
		color 0.2s ease-in-out;
	padding: 1.2rem 3rem;

	&:hover {
		color: $off-black;
		background: $teal;
		text-decoration: line-through;
	}
}

.buttonText {
	display: inline-block;
	border: none;
	border-radius: 50px;
	color: $off-black;
	line-height: 1 !important;
	text-decoration: none;
	transition: text-decoration 0.2s ease-in-out, color 0.2s ease-in-out;
	padding: 0;

	&:hover {
		color: $teal;
		text-decoration: line-through;
	}
}

.mainContainer {
	color: $off-black;

	:global {
		@import "../../../../../styles/etc/utilities";

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $heading-font;
			font-style: italic;
			text-transform: uppercase;
			margin-left: -0.5rem;
		}

		h2,
		h3 {
			letter-spacing: -3px;
		}

		h4 {
			letter-spacing: -2px;
		}

		h5 {
			letter-spacing: -1px;
		}

		p,
		a {
			font-size: 1rem;
			font-family: $body-font;
			font-optical-sizing: auto;
			font-weight: 400;
			line-height: 1.6;
		}
	}
}

.marquee {
	border-top: 2px solid $off-black;
	border-bottom: 2px solid $off-black;
	color: $off-black;
	padding: 20px 0 20px 0;
	font-family: "Roboto Mono", monospace;
	font-weight: 400;

	a {
		color: inherit;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: $teal;
		}
	}

	:global {
		.artistMarquee {
			margin-bottom: 0;
			display: flex;
			gap: 2rem;
		}

		.rfm-child {
			padding-left: 1rem;
			padding-right: 1rem;

			.you_tick {
				font-style: italic;
				text-decoration: underline !important;
				font-weight: 900;
			}
		}
	}
}

.titleContainer {
	display: grid;
	max-height: 1080px;

	& > * {
		grid-column: 1;
		grid-row: 1;
	}

	> img {
		max-height: 1080px;

		&:first-child,
		&:nth-child(4) {
			width: 100%;
			object-fit: cover;
			object-position: left;
		}

		&:first-child {
			height: 100%;
			max-height: 1080px;
		}

		&:nth-child(2) {
			max-width: 326px;
			width: 60%;
			height: 100%;
			object-fit: cover;
			object-position: right;
		}

		&:nth-child(3) {
			display: none;
			justify-self: flex-end;
			max-width: 150px;
			width: 25%;
			height: 100%;
			object-fit: cover;
			object-position: left;
		}
	}

	.titleContent {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 6.25rem;
		padding-right: 2rem;
		padding-bottom: 6.25rem;
		padding-left: 2rem;

		.logoContainer {
			position: relative;
			width: 65%;
			max-width: 13.75rem;
			margin-bottom: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;

			.ForGrizzFans,
			.ByGrizzFans {
				position: absolute;
				display: none;
				width: 12rem;
			}

			.logo {
				width: 100%;
				height: auto;
			}
		}

		h1 {
			font-size: 3.75rem;
			line-height: 0.9;
			letter-spacing: -2px;
			margin-bottom: 2.5rem;

			& + p {
				font-size: 1rem;
				text-align: center;
				margin: 0 2.5rem 1.5rem;
			}

			& ~ .buttonText {
				font-size: 1.5rem;
			}
		}
	}
}

.designersContainer {
	position: relative;
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	padding-left: 2rem;
	padding-right: 2rem;
	text-align: center;
	overflow: hidden;

	.imageDecor {
		position: absolute;
		height: auto;

		&.left {
			width: 20rem;
			top: 25%;
			left: -19rem;

			&.bottom {
				width: 25rem;
				top: 65%;
				left: -24rem;
			}
		}

		&.right {
			width: 35rem;
			top: 40%;
			right: -34rem;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	h2 {
		font-size: 1.65rem;
		margin-bottom: 1.25rem;
	}

	.designers {
		display: grid;
		grid-template-columns: 1fr;
		align-items: stretch;
		column-gap: 2.5rem;
		row-gap: 2.5rem;
		margin-top: 3.75rem;
		scroll-margin-top: 3.75rem;

		.designerItem {
			display: flex;
			flex-direction: column;
			align-items: center;

			&:first-child {
				.designerItemHeader {
					position: relative;

					&::after {
						content: "next up";
						position: absolute;
						top: -1.25rem;
						font-size: 1.15rem;
						font-family: $heading-font;
						font-optical-sizing: auto;
						font-weight: 400;
						line-height: 1;
						text-transform: uppercase;
						background-color: $teal;
						color: $off-black;
						padding: 0.5rem 1.5rem;
						border-radius: 2rem;
					}
				}
			}

			.designerItemHeader {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0.125rem solid $off-black;
				padding: 3.75rem;
				flex: 1 1 auto;
				aspect-ratio: 1 / 1;

				img {
					max-width: 100%;
				}
			}

			.separator {
				height: 0.125rem;
				width: 100%;
				background-color: $off-black;
				max-width: 6.25rem;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
			}

			.designerItemMeta {
				.pillButton {
					width: 100%;
					padding: 1rem 1.25rem;
				}
			}
		}
	}
}

.interestContainer {
	background-image: url("#{$seasonBaseURL}img/Grid_OffWhiteBackground_1920x1080.jpg");
	background-position: 100%;
	mix-blend-mode: exclusion;
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	padding-left: 2rem;
	padding-right: 2rem;

	> div {
		display: flex;
		flex-direction: column;
		gap: 3.75rem;
	}

	.interestBlock {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.interestBlockMeta {
			border: 0.125rem solid $off-black;
			padding: 2rem;

			h4 {
				margin-bottom: 1rem;
			}

			p {
				margin-bottom: 0;
			}
		}

		.separator {
			height: 0.125rem;
			width: 100%;
			background-color: $off-black;
			max-width: 6.25rem;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}

	.pillButton {
		background-color: #fff;
		color: $off-black;
		mix-blend-mode: exclusion;
		min-width: fit-content;

		&:hover {
			background: $teal;
		}
	}
}

.footer {
	background-color: $off-black;
	height: 40vh;
	display: grid;
	place-items: center;

	.footerLinks {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5em;

		a {
			display: inline-block;
			font-size: 1.25rem;
			line-height: 1;
			color: $med-gray;
			text-decoration: none;
			font-weight: normal;
			transition: color 0.2s ease-in-out;

			&:hover {
				color: $teal;
			}
		}
	}
}

@media (min-width: 576px) {
	.designersContainer {
		h2 {
			font-size: 2.25rem;
		}
	}
}

@media (min-width: 768px) {
	.titleContainer {
		> img {
			&:nth-child(2) {
				width: 40%;
			}
		}

		.titleContent {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: 6.25rem;
			padding-bottom: 6.25rem;

			.logoContainer {
				.ForGrizzFans,
				.ByGrizzFans {
					display: inline-block;
					top: 1rem;
				}

				.ForGrizzFans {
					right: 100%;
				}

				.ByGrizzFans {
					left: 100%;
				}
			}

			h1 {
				font-size: 4.5rem;
				letter-spacing: unset;

				& + p {
					font-size: 1.25rem;
				}
			}
		}
	}

	.designersContainer {
		h2 {
			font-size: 3rem;
			margin-bottom: 2rem;
		}

		.designers {
			grid-template-columns: repeat(2, 1fr);
			align-items: stretch;
			column-gap: 2.5rem;
			row-gap: 2.5rem;
			margin-top: 3.75rem;

			.designerItem {
				position: relative;

				&:first-child {
					grid-column-start: 1;
					grid-column-end: 3;
				}

				.designerItemHeader {
					img {
						width: 100%;
						max-width: 20rem;
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.titleContainer {
		.titleContent {
			h1 {
				font-size: 6.25rem;

				& ~ .buttonText {
					font-size: 2rem;
				}
			}
		}
	}

	.designersContainer {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		padding-left: 2.5rem;
		padding-right: 2.5rem;

		.designers {
			grid-template-columns: repeat(3, 1fr);

			.designerItem {
				&:first-child {
					grid-column-end: 4;
				}
			}
		}
	}

	.interestContainer {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;

		.interestBlock {
			flex-direction: row;

			&:nth-child(even) {
				.interestBlockMeta {
					order: 3;
				}

				.separator {
					order: 2;
				}

				.interestBlockCTA {
					order: 1;
				}
			}

			.interestBlockMeta {
				border: 0.125rem solid $off-black;
				padding: 2rem;
				width: 60%;

				h4 {
					margin-bottom: 1rem;
				}

				p {
					margin-bottom: 0;
				}
			}

			.separator {
				flex: 1 1 auto;
				width: unset;
				max-width: unset;
				margin: 0;
			}
		}
	}

	.footer {
		.footerLinks {
			flex-direction: row;

			a {
				font-size: 1.6rem;
			}

			span {
				display: block;
				height: 1.75rem;
				width: 0.125rem;
				background-color: $med-gray;
			}
		}
	}
}

@media (min-width: 1200px) {
	.titleContainer {
		.titleContent {
			padding-top: 12.5rem;
			padding-bottom: 12.5rem;
		}

		> img {
			&:nth-child(3) {
				display: inline-block;
			}
		}
	}

	.designersContainer {
		.imageDecor {
			&.left {
				width: 20rem;
				left: -17rem;
			}

			&.right {
				width: 35rem;
				right: -32.5rem;
			}
		}

		.designersDescription {
			p {
				font-size: 1.15rem;
			}
		}
	}
}

@media (min-width: 1920px) {
}
